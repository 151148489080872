/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import { auth } from "@/config/firebase";
import {
    signOut,
    signInWithPopup,
    GoogleAuthProvider
} from "firebase/auth";

import { db } from "@/config/firebase";
import {
    doc,
    getDoc
} from "firebase/firestore";
// import router from "@/router";

const state = {
    currentUser: null,
}

const getters = {
    getUserInfo: (state) => state.currentUser
}

const mutations = {
    setUserInfo: (state, payload) => state.currentUser = payload,
    clearUserInfo: (state) => state.currentUser = null
}

const actions = {
    async LoginWithGoogle({ commit }) {
        return new Promise((resolve, reject) => {
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider).then(userSnapshot => {
                console.log(userSnapshot.user);
                commit('setUserInfo', userSnapshot.user)
                // router.replace('/')
                resolve(userSnapshot.user)
                location.reload()
            }).catch(err => {
                console.log(err);
                // router.push('/login')
                reject(err)
            })
        })
    },

    async LogoutUser({ commit }) {
        return new Promise((resolve, reject) => {
            signOut(auth).then((res) => {
                console.log('logout done', res);
                commit('clearUserInfo')
                // router.push('/login')
                resolve()
            }).catch(err => {
                console.log('erro', err);
                reject(err)
            })
        })
    },

    async FetchUser({ commit }) {
        return new Promise((resolve, reject) => {
            let user = auth.currentUser
            if (user == null) {
                commit('clearUserInfo')
                resolve({
                    success: false,
                    message: 'User is Not Authorised'
                })
            } else {
                let docRef = doc(db, 'admin', user.uid)
                getDoc(docRef).then(docSnap => {
                    if (docSnap.exists()) {
                        commit('setUserInfo', user)
                        resolve({
                            success: true,
                            message: 'User is Authorised'
                        })
                    } else {
                        resolve({
                            success: false,
                            message: 'User is Not Authorised'
                        })
                        console.log('User Not Found');
                        commit('clearUserInfo')
                        signOut(auth);
                    }
                }).catch(err => {
                    commit('clearUserInfo')
                    signOut(auth);
                    reject(err)
                })
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
