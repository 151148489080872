import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from "@/config/firebase";

// tiptapVuetify plugin
import './plugins/tiptap-vuetify'
// import './plugins/gtag'
// import '@babel/polyfill'


Vue.config.productionTip = false


auth.onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})