<template>
  <v-app style="background-color: #f5f8fc">
    <!-- <v-system-bar app dark color="success">
      <v-icon>mdi-information-outline</v-icon>
      <span>Application is running in Beta Version</span>
    </v-system-bar> -->
    <AppSnackbarVue />
    <AppToolbarVue v-if="$route.meta.requireAuth" />
    <AppDrawerVue v-if="$route.meta.requireAuth" />

    <v-main style="height: 100%" v-if="checkingStatus" class="fill-height">
      <v-container
        fluid
        class="pa-0 ma-0 fill-height"
      >
        <v-row>
          <v-col class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-main style="height: 100%" v-else>
      <v-slide-y-reverse-transition>
        <router-view v-show="show" />
      </v-slide-y-reverse-transition>
    </v-main>
  </v-app>
</template>

<script>
import AppDrawerVue from "./components/core/AppDrawer.vue";
import AppToolbarVue from "./components/core/AppToolbar.vue";
import { auth } from "@/config/firebase";
import router from "./router";
import AppSnackbarVue from "./components/core/AppSnackbar.vue";

export default {
  name: "App",
  components: {
    AppDrawerVue,
    AppToolbarVue,
    AppSnackbarVue,
  },
  mounted() {
    if (auth.currentUser) {
      this.$store.commit("authStore/setUserInfo", auth.currentUser);
    } else {
      router.replace("/login");
    }
  },
  async beforeCreate() {
    this.checkingStatus = true;
    try {
      let res = await this.$store.dispatch("authStore/FetchUser");
      res = await res;
      console.log(res);
      this.checkingStatus = false;
    } catch (error) {
      this.checkingStatus = false;
      console.log(error);
    }
  },
  created() {
    this.show = true;
  },
  data: () => ({
    show: false,
    checkingStatus: true,
  }),
};
</script>
