import Vue from 'vue'
import Vuex from 'vuex'
import authStore from './modules/auth.store'
import speakersStore from './modules/speakers.store'
import eventsStore from './modules/events.store'
import uploadStore from './modules/upload.store'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: true,
    snackbar: {
      isSnackbarVisible: false,
      snackbarText: ''
    }
  },
  getters: {
    GET_SNACKBAR: (state) => state.snackbar
  },
  mutations: {
    SET_DRAWER: (state, payload) => (state.drawer = payload),
    TOGGLE_DRAWER: (state) => (state.drawer = !state.drawer),
    SET_SNACKBAR: (state, payload) => (state.snackbar.isSnackbarVisible = true, state.snackbar.snackbarText = payload),
    TOGGLE_SNACKBAR: (state) => (state.snackbar.isSnackbarVisible = !state.snackbar.isSnackbarVisible)
  },
  actions: {
  },
  modules: {
    authStore,
    speakersStore,
    eventsStore,
    uploadStore
  }
})
