/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import { storage } from "@/config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const state = {
}

const getters = {
}

const mutations = {
    doNothing: (state) => (state)
}

const actions = {
    async uploadFile({commit}, data){
        return new Promise((resolve, reject) => {
            // Points to the root reference
            const storageRef = ref(storage);
            // Points to 'images'
            const imagesRef = ref(storageRef, `${data.type}/${data.id}.${data.ext}`);
            
            uploadBytes(imagesRef, data.file).then((snap)=>{
                console.log('snap upload: ', snap);

                getDownloadURL(imagesRef).then(url=>{
                    resolve({
                        success: true,
                        url: url,
                        message: 'File Uploaded Successfully'
                    })
                    commit('doNothing')
                }).catch(err=>{
                    console.log('err in uploading: ', err);
                    reject({
                        success: false,
                        error: err
                    })
                })
                
                
            }).catch(err=>{
                console.log('err in uploading: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
