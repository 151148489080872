<template>
  <v-navigation-drawer
    clipped
    color="#F5F8FC"
    floating
    v-model="drawer"
    app
    width="300px"
  >
    <!-- <v-list-item class="py-n5 px-4">
      <v-btn class="py-6" depressed dark color="#0048D0" style="margin-left: auto;margin-right: auto;width: 260px;border-radius: 8px;">+ Add Requirement</v-btn>
    </v-list-item> -->

    <!-- style="border: 1px solid #e0e0e0 !important" -->
    <v-list-item class="py-0">
        <v-list-item-avatar>
          <v-img
            :src="
              currentUser?.photoURL
                ? currentUser.photoURL
                : 'https://raw.githubusercontent.com/WTM-India/iwdindia-2021/main/src/assets/img/common/maleAvatar.jpg'
            "
          ></v-img>
        </v-list-item-avatar>
  
        <v-list-item-content>
          <v-list-item-title
            class="google-font"
          >{{ currentUser?.displayName || 'User Name' }}</v-list-item-title>
          <v-list-item-subtitle
            class="google-font"
          >{{ currentUser?.email || 'example@gmail.com' }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    <v-divider></v-divider>

    <v-list dense shaped>
      <!-- Home -->
      <v-list-item to="/" dense color="primary">
        <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <!-- Home -->

      <!-- Events -->
      <v-list-item to="/events" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-lan</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Events</v-list-item-title>
      </v-list-item>
      <!-- Events -->

      <!-- Speakers -->
      <v-list-item to="/speakers" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-lan</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Speakers</v-list-item-title>
      </v-list-item>
      <!-- Speakers -->
    </v-list>
    <!-- Management -->
    <!-- Brand Requirement -->

    <!-- Brand Requirement -->
    <v-divider></v-divider>
    <v-list dense shaped>
      <v-list-item
        href="https://techferment.com"
        target="_blank"
        class="my-0 py-0"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title style="font-size: 92%">
            TechFerment Website</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <!-- <v-list dense shaped>
        <v-list-item to="/profile" dense color="primary" shaped>
          <v-list-item-icon color="primary">
            <v-icon>mdi-account-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>
      </v-list> -->
      <v-divider></v-divider>
      <div class="pa-3">
        <p>
          <span>
            <a
              href="https://techferment.com"
              target="_blank"
              style="text-decoration: none"
              >TechFerment</a
            >
          </span>
          <span
            class="google-font"
            style="color: #616161; font-size: 80%; float: right"
            >Version: 0.0.1
          </span>
        </p>
      </div>
    </template>
  </v-navigation-drawer>
</template>
  
  <script>
export default {
  name: "AppDrawer",
  data: () => ({
    mini: true,
  }),
  mounted() {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    currentUser: {
      get() {
        return this.$store.state.authStore.currentUser;
      },
    },
  },
};
</script>
  