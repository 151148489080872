<template>
  <v-app-bar
    app
    fixed
    clipped-left
    class="black--text"
    color="#F5F8FC"
    elevation="0"
    height="70"
  >
    <v-app-bar-nav-icon
      aria-label="Hamburger Btn"
      @click="toggleDrawer"
    ></v-app-bar-nav-icon>

    <v-toolbar-title class="px-0" style="margin-right: 200px">
      <router-link
        to="/"
        class="grey--text text--darken-2"
        style="text-decoration: none; font-size: 110%"
      >
        TechFerment Hub</router-link
      >
    </v-toolbar-title>

    <!-- <v-text-field
      class="my-2"
      hide-details
      style="border-radius: 12px"
      flat
      solo
      prepend-inner-icon="mdi-layers-search"
      placeholder="Search Pages"
    >
    </v-text-field> -->

    <v-spacer></v-spacer>
    <!-- <v-chip color="warning" style="color: black" class="mx-0"
      >Super Admin</v-chip
    > -->
    <v-btn fab small text color="#616161" class="mx-3">
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          small
          text
          color="#616161"
          class="mx-3"
          href="https://drive.google.com/file/d/1Cmrx3X40lkHPs-vy79N5x0klQIbLP6b_/view?usp=sharing"
          target="_blank"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </template>
      <span>User Guide</span>
    </v-tooltip> -->

    <v-btn
      color="error"
      @click="$store.dispatch('authStore/LogoutUser')"
      outlined
      style="border: 1px solid #ff5252"
      rounded
    >
      <span class="mr-2">Logout</span>
      <v-icon small>mdi-open-in-new</v-icon>
    </v-btn>

    <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            size="40"
            v-bind="attrs"
            v-on="on"
            class="ml-8"
            @click="$store.dispatch('authStore/LOGOUT_USER')"
            style="cursor: pointer"
          >
            <img src="https://picsum.photos/200" alt="User" />
          </v-avatar>
        </template>
        <span>Logout</span>
      </v-tooltip> -->
  </v-app-bar>
</template>
    
    <script>
export default {
  name: "AppToolbar",
  components: {},
  data: () => ({}),
  mounted() {},
  computed: {},
  methods: {
    toggleDrawer() {
      this.$store.commit("TOGGLE_DRAWER");
    },
  },
};
</script>
    
    <style>
</style>