/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import { db } from "@/config/firebase";
import {
    collection,
    addDoc,
    getDocs,
    serverTimestamp,
    updateDoc,
    doc,
    getDoc,
    deleteDoc,
    setDoc
    // query, 
    // orderBy 
} from "firebase/firestore";

const state = {
}

const getters = {
}

const mutations = {
    doNothing: (state) => (state)
}

const actions = {
    async AddNewEvent({ commit, rootState }, data) {
        data.createdAt = serverTimestamp()
        data.createdBy = rootState.authStore.currentUser.uid
        return new Promise((resolve, reject) => {
            addDoc(collection(db, "events"), data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Event Added Successfully'
                })
            })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    },

    async getAllEvents({ commit }) {
        let events = []
        return new Promise((resolve, reject) => {
            getDocs(collection(db, "events")).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    events.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: events
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    async updateEvent({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, 'events', data.docid)
            data.updatedAt = serverTimestamp()
            data.updatedBy = rootState.authStore.currentUser.uid
            delete data.docid
            updateDoc(docRef, data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Event Data Updated'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })



        })
    },

    async getEventInfo({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, 'events', docid)
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: docSnap.data()
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

    async deleteEvent({ commit }, docid) {
        return new Promise((resolve, reject) => {
            deleteDoc(doc(db, "events", docid)).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Event Removed Successfully'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

    async setBanner({ commit }, data) {
        return new Promise((resolve, reject) => {
            setDoc(doc(db, "config", "banner"), data).then(()=>{
                resolve({
                    success: true,
                    message: 'Banner Updated'
                })
                commit('doNothing')
            }).catch(err=>{
                console.log('err', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    async getBanner({ commit }) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, 'config', "banner")
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: docSnap.data()
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
