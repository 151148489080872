<template>
  <v-snackbar v-model="isSnackbarVisible" timeout="5000">
    {{ snackbarText }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="toggleSnackbar">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "AppSnackbar",
  data: () => ({}),
  computed: {
    snackbarText: {
      get() {
        return this.$store.getters["GET_SNACKBAR"].snackbarText;
      },
    },
    isSnackbarVisible: {
      get() {
        return this.$store.getters["GET_SNACKBAR"].isSnackbarVisible;
      },
      set(val) {
        this.$store.commit("SET_SNACKBAR", val);
      },
    },
  },
  methods: {
    toggleSnackbar() {
      this.$store.commit("TOGGLE_SNACKBAR");
    },
  },
};
</script>

<style>
</style>