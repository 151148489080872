<template>
  <v-list two-line style="border-radius:12px" rounded class="my-n5">
      <v-list-item :key="event.docid" @click="goToEventDetails(event.docid)" style="border-radius:12px">
        <v-list-item-avatar>
          <v-icon
          >mdi-clipboard-text</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ event.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ event.startdate }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "EventStatsList",
  props: ["event"],
  methods: {
    goToEventDetails(id) {
      this.$router.push("/events/" + id);
    },
  },
};
</script>

<style>
</style>