// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUw3Bb4qOUlV8wG--gT-P62slB6Fni_Es",
  authDomain: "techferment-community.firebaseapp.com",
  projectId: "techferment-community",
  storageBucket: "techferment-community.appspot.com",
  messagingSenderId: "149421165684",
  appId: "1:149421165684:web:cc95b7a4b938777dba5521"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app);

export {db, auth, storage}