<template>
  <v-main class="px-4 py-0">
    <v-container fluid class="white" style="border-radius: 12px !important">
      <v-row>
        <v-col md="12" class="pa-5">
          <p class="my-0">
            Welcome <b>{{ currentUser?.displayName || "Name" }}</b>
          </p>
          <p class="my-0">Email {{ currentUser?.email || "Email" }}</p>

          <v-container fluid class="px-0">
            <v-row>
              <v-col
                md="2"
                sm="3"
                cols="6"
                v-for="(item, index) in summery"
                :key="index"
              >
                <v-container
                  fluid
                  style="background-color: #f0f0f0; border-radius: 12px"
                >
                  <v-row justify="center" align="center" class="px-md-1">
                    <v-col md="6" cols="12">
                      <v-icon size="20">{{ item.icon }}</v-icon>
                      <p class="my-1" style="font-size: 80%">{{ item.name }}</p>
                    </v-col>
                    <v-col md="6" cols="6" class="text-md-right">
                      <p class="my-0" style="font-size: 150%">
                        {{ item.value }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="mt-4 px-0">
      <v-row>
        <v-col md="4">
          <div class="white pa-5" style="border-radius: 12px !important">
            <p class="primary--text"><b>Current Events</b></p>
            <EventStatsListVue v-for="(item, index) in currentEvents" :key="index" :event="item"/>
            <span v-if="!currentEvents.length">No Events Found</span>
          </div>
        </v-col>

        <v-col md="4">
          <div class="white pa-5" style="border-radius: 12px !important">
            <p class="primary--text"><b>Upcoming Events</b></p>
            <EventStatsListVue v-for="(item, index) in upcomingEvents" :key="index" :event="item"/>
            <span v-if="!upcomingEvents.length">No Events Found</span>
          </div>
        </v-col>

        <v-col md="4">
          <div class="white pa-5" style="border-radius: 12px !important">
            <p class="primary--text"><b>Past Events</b></p>
            <EventStatsListVue v-for="(item, index) in pastEvents.slice(0,5)" :key="index" :event="item"/>
            <span v-if="!pastEvents.length">No Events Found</span>
          </div>
        </v-col>
        
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import EventStatsListVue from '@/components/common/EventStatsList.vue';

export default {
  name: "HomePage",
  components: {
    EventStatsListVue
  },
  data: () => ({
    loading: false,
    summery: [
      {
        icon: "mdi-account-details-outline",
        value: "3",
        name: "Users",
      },
      {
        icon: "mdi-apps",
        value: 67,
        name: "Applications",
      },
      {
        icon: "mdi-file-document",
        value: 181,
        name: "Blogs",
      },
      {
        icon: "mdi-account-group-outline",
        value: 347,
        name: "Talks",
      },
      {
        icon: "mdi-code-braces",
        value: 82,
        name: "Workshops",
      },
    ],
    events: [],
    currentEvents: [],
    upcomingEvents: [],
    pastEvents: [],
  }),
  mounted() {
    this.getAllEvents();
  },
  created() {
    document.title = "Home | TechFerment Hub";
  },
  computed: {
    currentUser: {
      get() {
        return this.$store.state.authStore.currentUser;
      },
    },
  },
  methods: {
    async getAllEvents() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch("eventsStore/getAllEvents");
        res = await res;
        if (res.success) {
          this.events = res.data;

          res.data.forEach((event) => {
            const comapiredResult = this.compareDate(
              event.startdate,
              event.enddate
            );
            if (comapiredResult == -1) this.pastEvents.push(event);
            else if (comapiredResult == 1) this.upcomingEvents.push(event);
            else this.currentEvents.push(event);
          });
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    compareDate(startdate = null, enddate = null) {
      const startEventDate = new Date(startdate).getTime();
      const endEventDate = new Date(enddate).getTime();
      const currentDate = new Date().getTime();
      return startEventDate < currentDate && endEventDate < currentDate
        ? -1
        : startEventDate > currentDate
        ? 1
        : 0;
    },
  },
};
</script>
