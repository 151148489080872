import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomePage.vue'
import { auth } from "../config/firebase";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '../views/EventsPage.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/events/:id',
    name: 'event-info',
    component: () => import(/* webpackChunkName: "event-info" */ '../views/EventInfoPage.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/add-event',
    name: 'add-event',
    component: () => import(/* webpackChunkName: "add-event" */ '../components/events/AddEditEventPage.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/edit-event/:id',
    name: 'edit-event',
    component: () => import(/* webpackChunkName: "edit-event" */ '../components/events/AddEditEventPage.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/speakers',
    name: 'speakers',
    component: () => import(/* webpackChunkName: "speakers" */ '../views/SpeakersPage.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/speakers/:id',
    name: 'speaker-page',
    component: () => import(/* webpackChunkName: "speaker-page" */ '../views/SpeakerPage.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      requireAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (auth.currentUser) {
      next();
      return
    } else {
      next('/login');
      return
    }
  } else if (auth.currentUser && to.name == "login-page") {
    next("/");
    return
  } else {
    next();
    return
  }
});


export default router
