/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import { db } from "@/config/firebase";
import {
    collection,
    addDoc,
    getDocs,
    serverTimestamp,
    updateDoc,
    doc,
    getDoc,
    deleteDoc,
    query,
    where
    // orderBy 
} from "firebase/firestore";

const state = {
}

const getters = {
}

const mutations = {
    doNothing: (state) => (state)
}

const actions = {
    async AddNewSpeaker({ commit, rootState }, data) {
        console.log('da ', data);
        data.createdAt = serverTimestamp()
        data.createdBy = rootState.authStore.currentUser.uid
        return new Promise((resolve, reject) => {
            addDoc(collection(db, "speakers"), data).then((res) => {
                console.log('res', res);
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Speaker Added Successfully'
                })
            })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    },

    async getAllSpeakers({ commit }) {
        let speakers = []
        return new Promise((resolve, reject) => {
            // query(citiesRef, orderBy("name"), limit(3))
            getDocs(collection(db, "speakers")).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    speakers.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: speakers
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    async updateSpeaker({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, 'speakers', data.docid)

            data.updatedAt = serverTimestamp()
            data.updatedBy = rootState.authStore.currentUser.uid
            delete data.docid
            updateDoc(docRef, data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Speaker Data Updated'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })



        })
    },

    async getSpeakerInfo({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, 'speakers', docid)
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: { ...docSnap.data(), ...{ docid: docid } }
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

    async deleteSpeaker({ commit }, docid) {
        return new Promise((resolve, reject) => {
            deleteDoc(doc(db, "speakers", docid)).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Speaker Removed Successfully'
                })

            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

    async getEventInfoBySpeaker({ commit }, docid) {
        let events = []
        return new Promise((resolve, reject) => {
            const docRef = collection(db, "events");
            let q = query(docRef, where("speakers", "array-contains", docid))

            getDocs(q).then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    events.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                resolve({
                    success: true,
                    data: events
                })
                commit('doNothing')
            }).catch(err => {
                console.log('err', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    async checkSpeakerUsername({ commit }, username) {
        return new Promise((resolve, reject) => {
            const docRef = collection(db, "speakers");
            let q = query(docRef, where("username", "==", username))

            getDocs(q).then(querySnapshot => {
                if(querySnapshot.docs.length){
                    resolve({
                        success: false,
                        message: 'Username is already taken'
                    })
                }else{
                    resolve({
                        success: true,
                        message: 'Username is available'
                    })
                }
                commit('doNothing')
            }).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
